import axios from 'axios';

// 创建 Axios 实例
const apiClient = axios.create({
  baseURL: 'https://hezi.hezi.one/php/', // 设置 API 的基础 URL
  timeout: 30000, // 请求超时时间
});

// 请求拦截器
apiClient.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
    
    
// 响应拦截器
apiClient.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });
  export default apiClient;

